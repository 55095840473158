import { Tooltip, Typography } from 'antd';
import { LockTwoTone } from '@ant-design/icons';

import {
  USER_ID,
  hasTableQuery,
  hasSomeTablesQuery,
} from '../utils/privileges';

const LockedBase = ({ hide }) => {
  const message = USER_ID
    ? 'Locked. Contact us to ask about access!'
    : 'Locked. Login or register to access public datasets.';
  const href = USER_ID ? '/' : '/auth/login';
  return hide ? null : (
    <Tooltip title={message} overlayInnerStyle={{ textAlign: 'center' }}>
      <Typography.Link href={href}>
        <LockTwoTone />
      </Typography.Link>
    </Tooltip>
  );
};

export const LockedTable = ({ id }) => <LockedBase hide={hasTableQuery(id)} />;

export const LockedTables = ({ tables }) => (
  <LockedBase hide={hasSomeTablesQuery(tables)} />
);
