
import qs from 'qs';

const queryOptions = {
  arrayFormat: 'repeat',
  skipNulls: true,
  serializeDate: d => d.toISOString(),
};

export const qsStringify = params => qs.stringify(params, queryOptions);
