import React from 'react';

import { getMathRandomFromString } from './crypto';

import {
  red,
  volcano,
  orange,
  gold,
  yellow,
  lime,
  green,
  cyan,
  blue,
  geekblue,
  purple,
  magenta,
} from '@ant-design/colors';

export const getAntdColorFromString = (string, shade) => {
  const colorNames = [
    'red',
    'volcano',
    'orange',
    'gold',
    // 'yellow', // too light
    'lime',
    'green',
    'cyan',
    'blue',
    'geekblue',
    'purple',
    'magenta',
  ];
  // Candidate colours
  const colors = [
    red[shade],
    volcano[shade],
    orange[shade],
    gold[shade],
    // yellow[shade], // too light
    lime[shade],
    green[shade],
    cyan[shade],
    blue[shade],
    geekblue[shade],
    purple[shade],
    magenta[shade],
  ];
  // If the string is a known color name then return
  const colorNameIndex = colorNames.findIndex(c => c === string);
  if (colorNameIndex !== -1) return colors[colorNameIndex];
  // Deterministically pick a random index from string
  const random = getMathRandomFromString(string);
  const index = Math.floor(colors.length * random());
  // Return this pick
  return colors[index];
};

export const lineClampStyle = lineClamp => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: lineClamp,
  lineClamp,
  WebkitBoxOrient: 'vertical',
});

// https://stackoverflow.com/a/60565322/7978198
export const getNodeText = node => {
  if (['string', 'number'].includes(typeof node)) return node;
  if (node instanceof Array) return node.map(getNodeText).join('');
  if (typeof node === 'object' && node) return getNodeText(node.props.children);
};

export const EllipsisCellWrapper = ({
  children,
  title,
  lineClamp = 1,
  style,
}) => (
  <div
    title={title || getNodeText(children)}
    style={{ ...lineClampStyle(lineClamp), ...style }}
  >
    {children}
  </div>
);

export const toKebabCase = str =>
  str &&
  str
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map(x => x.toLowerCase())
    .join('-');

export const toTitleCase = str =>
  str &&
  str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
