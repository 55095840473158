import React from 'react';

import { Avatar, Tooltip } from 'antd';

import { getAntdColorFromString } from '../utils/styles';

export const UserAvatar = ({ user, ...props }) => {
  const backgroundColor = getAntdColorFromString(
    user.id || user.username || `${user.first_name}${user.last_name}`,
    3,
  );
  return (
    <Tooltip title={`${user.first_name} ${user.last_name}`}>
      <Avatar
        size={28}
        gap={6}
        {...props}
        style={{ backgroundColor, border: 'none', ...(props?.style || {}) }}
      >
        {user.first_name[0].toUpperCase()}
        {user.last_name[0].toUpperCase()}
      </Avatar>
    </Tooltip>
  );
};

export const UserAvatarGroup = ({
  users,
  maxCount = 3,
  size = 28,
  ...props
}) => (
  <Avatar.Group maxCount={maxCount} size={size}>
    {(users || []).map((user, index) => (
      <UserAvatar
        user={user}
        key={user.username}
        size={size}
        {...props}
        // Leftmost on top
        style={{ zIndex: `${99 - index}`, ...props.style }}
      />
    ))}
  </Avatar.Group>
);
