import React, { useState, useEffect } from 'react';
import {
  useQueryParam,
  NumberParam,
  StringParam,
  withDefault,
  ArrayParam,
} from 'use-query-params';
import ErrorBoundary from 'src/components/ErrorBoundary';
import { SupersetClient } from '@superset-ui/core';
import rison from 'rison';

import { Breadcrumb, Table, Tooltip, Input, Typography, Radio } from 'antd';
import {
  EditOutlined,
  StarOutlined,
  StarFilled,
  UnorderedListOutlined,
  AppstoreOutlined,
  DatabaseOutlined,
  PieChartTwoTone,
} from '@ant-design/icons';

import { Attribution } from './components/Attribution';
import { Providers } from './components/Providers';
import Layout, { attachToRoot } from './components/Layout';
import { TentacleCardGrid, TentacleCard } from './components/Card';
import { TentacleDatasetThumbnail } from './components/Thumbnail';
import { UserAvatar } from './components/UserAvatar';
import { TaxonomiesTreeSelect } from './components/TaxonomiesFilter';
import { datasetCrypt } from './utils/crypto';
import { EllipsisCellWrapper } from './utils/styles';
import {
  DatasetAvailability,
  DatasetLatestUpdate,
} from './components/Statistics';
import { LockedTables as Locked } from './components/Locked';
import { IS_ADMIN, USER_ID, isCreatorOrOwner } from './utils/privileges';

const useGetDatasets = ({
  filters = [],
  page = 1,
  pageSize = 10,
  orderColumn = 'title',
  orderDirection = 'asc',
  refreshKey,
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(undefined);
  const [datasets, setDatasets] = useState(undefined);
  useEffect(() => {
    setError(undefined);
    setLoading(true);
    const risonParams = rison.encode({
      keys: ['none'],
      columns: [
        // Audit columns
        'created_on',
        'created_on_delta_humanized',
        'created_by',
        'changed_on',
        'changed_on_delta_humanized',
        'changed_by',
        // Tables info
        'tables.id',
        'tables.table_name',
        'tables.description',
        // Dataset columns
        'id',
        'title',
        'slug',
        'short_title',
        'description',
        'provider',
        'provider_url',
        'source',
        'source_url',
        'attribution',
        'license',
        'license_url',
        'status',
        'taxonomies',
        'thumbnail_id',
      ],
      filters: filters,
      page: page - 1,
      page_size: pageSize,
      order_column: orderColumn,
      order_direction: orderDirection,
    });
    // First get the list of datasets
    SupersetClient.get({
      endpoint: `/api/v1/tentacle_dataset/?q=${risonParams}`,
    })
      .then(({ json: datasetsJson }) => {
        const ids = (datasetsJson?.result || []).map(d => d.id);
        // If no IDs then we are done
        if (ids.length == 0) {
          setDatasets(datasetsJson);
          return;
        }
        // Get favourite statuses and interleave
        const risonIds = rison.encode(ids);
        SupersetClient.get({
          endpoint: `/api/v1/tentacle_dataset/favorite_status/?q=${risonIds}`,
        })
          .then(({ json: favJson }) => {
            const result = datasetsJson.result.map((d, i) => ({
              ...d,
              _favorite_status: favJson.result[i].value,
            }));
            setDatasets({ ...datasetsJson, result });
          })
          .catch(err => {
            setError(err);
          });
      })
      .catch(err => {
        setError(err);
      })
      .finally(() => setLoading(false));
  }, [
    JSON.stringify(filters),
    page,
    pageSize,
    orderColumn,
    orderDirection,
    refreshKey,
  ]);
  return { loading, error, datasets };
};

const FavoriteButton = ({ initialStatus, id, onError }) => {
  const [status, setStatus] = useState(initialStatus);
  const toggleStatus = () => {
    SupersetClient[status ? 'delete' : 'post']({
      endpoint: `/api/v1/tentacle_dataset/${id}/favstar`,
    })
      .then(({ json }) => {
        if (json?.status !== 'OK') {
          onError?.(json);
        } else {
          setStatus(!status);
        }
      })
      .catch(err => {
        onError?.(err);
      });
  };
  return !USER_ID ? null : (
    <Tooltip title="Star">
      {status ? (
        <StarFilled style={{ color: '#faad14' }} onClick={toggleStatus} />
      ) : (
        <StarOutlined style={{ color: '#ffd666' }} onClick={toggleStatus} />
      )}
    </Tooltip>
  );
};

const EditButton = ({ dataset, disabled }) =>
  IS_ADMIN || isCreatorOrOwner(dataset) ? (
    <Tooltip title="Edit">
      <Typography.Link
        href={`/tentacle_datasets/edit/${dataset.id}`}
        disabled={disabled}
      >
        <EditOutlined />
      </Typography.Link>
    </Tooltip>
  ) : null;

const DatasetsContent = () => {
  // Query parameters
  const [pageSize, setPageSize] = useQueryParam(
    'pageSize',
    withDefault(NumberParam, 10),
  );
  const [page, setPage] = useQueryParam('page', withDefault(NumberParam, 1));
  const [search, setSearch] = useQueryParam('search', StringParam);
  const [orderColumn, setOrderColumn] = useQueryParam(
    'orderColumn',
    withDefault(StringParam, 'title'),
  );
  const [orderDirection, setOrderDirection] = useQueryParam(
    'orderDirection',
    withDefault(StringParam, 'asc'),
  );
  const [view, setView] = useQueryParam(
    'view',
    withDefault(StringParam, 'grid'),
  );
  const [taxonomiesFilter, setTaxonomiesFilter] = useQueryParam(
    'taxonomiesFilter',
    withDefault(ArrayParam, []),
  );

  // Errors for various endpoints
  const [favoriteError, setFavoriteError] = useState(undefined);

  // Get datasets list
  const {
    loading: datasetsLoading,
    error: datasetsError,
    datasets,
  } = useGetDatasets({
    page,
    pageSize,
    orderColumn,
    orderDirection,
    filters: (() => {
      const taxonomiesFilters = (taxonomiesFilter || []).map(t => ({
        col: 'taxonomies',
        opr: 'cta',
        value: t,
      }));
      const searchFilters = !search
        ? []
        : search
            .split(' ')
            .filter(s => !!s)
            .map(s => ({
              col: 'title',
              opr: 'ct',
              value: s,
            }));
      return [...searchFilters, ...taxonomiesFilters];
    })(),
  });

  const pagination = {
    position: ['topRight', 'bottomRight'],
    current: page,
    pageSize: pageSize,
    pageSizeOptions: [5, 10, 20, 50],
    showSizeChanger: true,
    showQuickJumper: true,
    total: datasets?.count,
    onChange: (page, pageSize) => {
      setPage(page);
      setPageSize(pageSize);
    },
  };

  // Error will be caught and displayed by the ErrorBoundary
  const error = datasetsError || favoriteError;
  if (error) throw error;

  return (
    <div style={{ maxWidth: '1600px', margin: 'auto' }}>
      {/* Banner: search/view/add */}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'flex-end',
          gap: '8px',
          marginBottom: '16px',
        }}
      >
        <Input.Search
          placeholder="Search..."
          defaultValue={search}
          allowClear
          style={{ flexGrow: '2', width: '220px' }}
          onSearch={value => {
            setSearch(value || undefined);
            setPage(1);
          }}
        />
        <TaxonomiesTreeSelect
          style={{ flexGrow: '1', width: '160px' }}
          taxonomiesFilter={taxonomiesFilter}
          onChange={value => {
            setPage(1);
            setTaxonomiesFilter(value);
          }}
        />
        <Radio.Group
          value={view}
          onChange={({ target: { value } }) => setView(value)}
        >
          <Radio.Button value="list">
            <UnorderedListOutlined />
          </Radio.Button>
          <Radio.Button value="grid">
            <AppstoreOutlined />
          </Radio.Button>
        </Radio.Group>
        {/* TODO: Taxonomies filter?? */}
      </div>
      {/* View: grid/table  */}
      {view === 'grid' ? (
        <TentacleCardGrid
          dataSource={datasets?.result}
          loading={datasetsLoading}
          pagination={pagination}
          columnWidth={600}
          itemToCard={d => (
            <TentacleCard
              key={d.id}
              href={`/datasets/${datasetCrypt.encrypt(d.id)}`}
              avatar={
                <TentacleDatasetThumbnail
                  dataset={d}
                  size={48}
                  tooltip={false}
                />
              }
              title={
                <span>
                  <Locked tables={d.tables} /> {d.title}
                </span>
              }
              extra={
                <div
                  style={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    gap: '2px',
                  }}
                >
                  <FavoriteButton
                    initialStatus={d._favorite_status}
                    id={d.id}
                    onError={setFavoriteError}
                  />
                  <Tooltip title="Explore">
                    <Typography.Link
                      target="_blank"
                      href={`/charts/explore?dataset_id=${d.id}`}
                    >
                      <PieChartTwoTone />
                    </Typography.Link>
                  </Tooltip>
                  <EditButton dataset={d} />
                  {/* TODO: Delete? */}
                </div>
              }
              description={d?.description}
              meta={[
                `${d.tables.length} table${d.tables.length === 1 ? '' : 's'}`,
                // <Attribution dataset={d} useHref={false} />,
                <DatasetAvailability
                  id={d.id}
                  key="d-availability"
                  params={{
                    approximate_row_count: true,
                    approximate_size: true,
                    latest_update: true,
                  }}
                  errorText="Availability Unavailable"
                />,
                <DatasetLatestUpdate
                  id={d.id}
                  prefix="Updated "
                  key="d-latest-update"
                  params={{
                    approximate_row_count: true,
                    approximate_size: true,
                    latest_update: true,
                  }}
                  errorText="Updated Unavailable"
                />,
              ]}
              // tags={(d?.taxonomies || []).map(t => t.name)}
            />
          )}
        />
      ) : (
        <Table
          dataSource={(datasets?.result || []).map(d => ({
            key: d.id,
            ...d,
          }))}
          loading={datasetsLoading}
          pagination={pagination}
          onChange={(pagination, filters, sorter) => {
            if (sorter && !!sorter.order) {
              // Chop off the "end"
              var order = sorter.order.slice(0, -3);
              setOrderColumn(sorter.field);
              setOrderDirection(order);
            } else {
              setOrderColumn(undefined);
              setOrderDirection(undefined);
            }
          }}
          size="small"
          bordered={false}
          columns={[
            {
              dataIndex: '_favorite_status',
              align: 'center',
              render: (status, record) => (
                <FavoriteButton
                  initialStatus={status}
                  id={record.id}
                  onError={setFavoriteError}
                />
              ),
            },
            {
              dataIndex: 'title',
              title: 'Title',
              sorter: true,
              render: (value, record) => (
                <EllipsisCellWrapper lineClamp={1}>
                  <Typography.Link
                    href={`/datasets/${datasetCrypt.encrypt(record.id)}`}
                  >
                    <TentacleDatasetThumbnail dataset={record} size={24} />{' '}
                    <Locked tables={record.tables} /> {value || '[ Untitled ]'}
                  </Typography.Link>
                </EllipsisCellWrapper>
              ),
            },
            // {
            //   dataIndex: 'description',
            //   title: 'Description',
            //   width: '240px',
            //   render: (value, record) => (
            //     <EllipsisCellWrapper lineClamp={1}>{value}</EllipsisCellWrapper>
            //   ),
            //   responsive: ['xxl'],
            // },
            // {
            //   dataIndex: 'status',
            //   title: 'Status',
            //   sorter: true,
            //   render: (value, record) => value || 'Active',
            //   responsive: ['md'],
            // },
            {
              dataIndex: 'created_by',
              title: 'Created By',
              align: 'center',
              render: (value, record) => <UserAvatar user={value} size={24} />,
              responsive: ['md'],
            },
            {
              dataIndex: 'created_on_delta_humanized',
              title: 'Created',
              render: (value, record) => (
                <EllipsisCellWrapper>
                  {(value && value[0].toUpperCase() + value.slice(1)) ||
                    'Unknown'}
                </EllipsisCellWrapper>
              ),
              responsive: ['xxl'],
            },
            {
              dataIndex: 'changed_on_delta_humanized',
              title: 'Modified',
              render: (value, record) => (
                <EllipsisCellWrapper>
                  {(value && value[0].toUpperCase() + value.slice(1)) ||
                    'Unknown'}
                </EllipsisCellWrapper>
              ),
              responsive: ['xxl'],
            },
            {
              dataIndex: 'attribution',
              title: 'Attribution',
              render: (value, record) => (
                <EllipsisCellWrapper>
                  <Attribution dataset={record} />
                </EllipsisCellWrapper>
              ),
              responsive: ['lg'],
            },
            // {
            //   dataIndex: 'license',
            //   title: 'License',
            //   render: (value, record) => (
            //     <EllipsisCellWrapper>
            //       {record?.license_url ? (
            //         <Typography.Link href={record.license_url} target="_blank">
            //           {value}
            //         </Typography.Link>
            //       ) : (
            //         value
            //       )}
            //     </EllipsisCellWrapper>
            //   ),
            //   responsive: ['lg'],
            // },
            {
              title: 'Approximate Count',
              render: (value, record) => (
                <EllipsisCellWrapper>
                  <DatasetAvailability
                    id={record.id}
                    params={{
                      approximate_row_count: true,
                      approximate_size: true,
                      latest_update: true,
                    }}
                  />
                </EllipsisCellWrapper>
              ),
              responsive: ['lg'],
            },
            {
              title: 'Latest Update',
              render: (value, record) => (
                <EllipsisCellWrapper>
                  <DatasetLatestUpdate
                    id={record.id}
                    params={{
                      approximate_row_count: true,
                      approximate_size: true,
                      latest_update: true,
                    }}
                  />
                </EllipsisCellWrapper>
              ),
              responsive: ['lg'],
            },
            {
              dataIndex: 'edit',
              align: 'center',
              render: (_, record) => (
                <div
                  style={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    gap: '2px',
                  }}
                >
                  {
                    <Tooltip title="Explore">
                      <Typography.Link
                        target="_blank"
                        href={`/charts/explore?dataset_id=${record.id}`}
                      >
                        <PieChartTwoTone />
                      </Typography.Link>
                    </Tooltip>
                  }
                  <EditButton dataset={record} />
                  {/* TODO: Delete? */}
                </div>
              ),
              responsive: ['md'],
            },
          ]}
        />
      )}
    </div>
  );
};

const Datasets = () => (
  <Layout
    selectedKeys={['datasets']}
    sider={false}
    cookieNotice
    breadcrumb={
      <Breadcrumb separator=">">
        <Breadcrumb.Item key="datasets">
          <Typography.Link href={`/datasets`}>
            <DatabaseOutlined /> Datasets
          </Typography.Link>
        </Breadcrumb.Item>
      </Breadcrumb>
    }
    mainContent={
      <ErrorBoundary>
        <DatasetsContent />
      </ErrorBoundary>
    }
  />
);

attachToRoot(
  <Providers>
    <Datasets />
  </Providers>,
);
