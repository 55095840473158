import React, { useEffect, useRef } from 'react';

import { Avatar, Tooltip } from 'antd';

import { datasetCrypt } from '../utils/crypto';
import { getAntdColorFromString } from '../utils/styles';

export const TentacleDatasetThumbnail = ({
  dataset,
  shape = 'square',
  size = 96,
  tooltip = true,
  avatarProps = {},
}) => (
  <Tooltip title={tooltip && dataset?.title}>
    <Avatar
      shape={shape}
      size={size}
      // NOTE: This is just tuned by eye for small avatars (ChartsExplore).
      // It is paired with a monospace font-family to ensure differing-width
      // characters all look the same size.
      gap={7}
      src={
        dataset?.thumbnail_id &&
        `/api/v1/tentacle_dataset/thumbnail/${dataset.thumbnail_id}`
      }
      // alt={dataset?.thumbnail_id && 'Dataset Thumbnail'}  // NOTE: looks awful when images load slowly
      {...avatarProps}
      style={{
        cursor: dataset?.id && 'pointer',
        fontWeight: 'bold',
        fontFamily: '"Lucida Console", Courier, monospace',
        color: getAntdColorFromString(dataset?.id || dataset?.title || '', 8),
        backgroundColor: dataset?.thumbnail_id
          ? 'white'
          : getAntdColorFromString(dataset?.id || dataset?.title || '', 2),
        border: '1px solid #ddd',
        ...(avatarProps?.style || {}),
      }}
      // NOTE: Link as onClick here since if wrap all this in an <a> the
      // AvatarGroup does not stack the Avatars properly. Not ideal since
      // browser doesn't show link on hover, but couldn't hack a solution.
      onClick={() =>
        dataset?.id &&
        (location = `/datasets/${datasetCrypt.encrypt(dataset.id)}`)
      }
    >
      {dataset?.title?.[0]}
    </Avatar>
  </Tooltip>
);

export const TentacleDatasetThumbnails = ({
  datasets,
  maxCount = 2,
  overlap = 0.4,
  ...props
}) => {
  // NOTE: marginLeft logic below is to consistently increase the overlap of
  // the avatar group icons, including for the final span containing "+X".
  const marginLeft = `-${overlap * (props?.size || 96)}px`;
  const groupRef = useRef();
  useEffect(() => {
    const avatarSpans = groupRef.current?.querySelectorAll('.ant-avatar');
    if (avatarSpans.length > 1) {
      avatarSpans[avatarSpans.length - 1].style.marginLeft = marginLeft;
    }
  });
  return (
    <div ref={groupRef}>
      <Avatar.Group maxCount={maxCount} size={props?.size || 96}>
        {datasets.map((d, i) => (
          <TentacleDatasetThumbnail
            key={d?.id || d?.title}
            dataset={d}
            {...props}
            avatarProps={{
              style: {
                zIndex: 99 - i,
                marginLeft: i === 0 || i >= maxCount ? 0 : marginLeft,
                ...(props?.avatarProps?.style || {}),
              },
            }}
          />
        ))}
      </Avatar.Group>
    </div>
  );
};
