import React from 'react';
import { Typography } from 'antd';

import { getNodeText } from '../utils/styles';

export const Attribution = ({ dataset, useHref = true }) => {
  // Node to return (or its raw text)
  var node;
  // Constants
  const sourceUrl = dataset?.source_url;
  const providerUrl = dataset?.provider_url;
  const url = sourceUrl || providerUrl;
  // If attribution set then use it directly
  if (dataset?.attribution) {
    node = url ? (
      <Typography.Link href={url} target="_blank">
        {dataset.attribution}
      </Typography.Link>
    ) : (
      dataset.attribution
    );
  }
  // Otherwise construct from source/provider
  else {
    // Source node
    const sourceMeta =
      dataset?.source &&
      (sourceUrl ? (
        <Typography.Link href={sourceUrl} target="_blank">
          {dataset.source}
        </Typography.Link>
      ) : (
        dataset.source
      ));
    // Provider node
    const providerMeta =
      dataset?.provider &&
      (providerUrl ? (
        <Typography.Link href={providerUrl} target="_blank">
          {dataset.provider}
        </Typography.Link>
      ) : (
        dataset.provider
      ));
    // Put them together appropriately
    if (dataset?.source === dataset?.provider) {
      node = sourceMeta;
    } else if (!dataset?.source) {
      node = <span>Unknown via {providerMeta}</span>;
    } else {
      node = (
        <span>
          {sourceMeta} via {providerMeta}
        </span>
      );
    }
  }
  return useHref ? node : <span>{getNodeText(node)}</span>;
};
