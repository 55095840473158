import React, { useState, useEffect } from 'react';
import { SupersetClient } from '@superset-ui/core';
import { TreeSelect } from 'antd';

import { qsStringify } from '../utils/fetch';

const _taxonomyToAntdTreeNode = taxonomy => {
  if (taxonomy.pId) {
    return taxonomy;
  }
  const depth = taxonomy.id.split('.').length;
  if (depth % 2) {
    throw 'Got unexpected odd taxonomy node';
  }
  // NOTE: Tree data needs integer IDs
  const pId = taxonomy.id.split('.').slice(0, -2).join('.');
  return {
    id: taxonomy.id,
    pId,
    value: taxonomy.id,
    key: taxonomy.id,
    title: taxonomy.name,
    isLeaf: !taxonomy.has_children,
  };
};

export const useTaxonomies = ({ taxonomiesFilter, loadTaxonomies }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(undefined);
  const [taxonomies, setTaxonomies] = useState([]);
  useEffect(() => {
    const initialTaxonomyDepth = 0;
    const endpoint = '/api/v1/tentacle_dataset/taxonomies/';
    // Collect requests to GET taxonomies endpoint
    let promises = [];
    // Define necessary variables for taxonomies requests
    const getTaxonomies = [...taxonomiesFilter, ...loadTaxonomies];
    const filterLength = getTaxonomies.length;
    const depth = filterLength
      ? getTaxonomies[filterLength - 1].split('.').length
      : 0;
    const highestDepthTaxonomies = getTaxonomies.filter(
      e => e.split('.').length === depth,
    );
    const checkStartDepth =
      taxonomies.map(e => e.id).includes(getTaxonomies[filterLength - 1]) ||
      !filterLength;
    let i = checkStartDepth ? depth : 0;
    // Construct the taxonomy requests
    let seedsAlreadyRequested = [];
    while (i <= depth) {
      // Determine the appropriate seed and level, looping over all
      const loopLength = highestDepthTaxonomies.length || 1;
      for (let j = 0; j < loopLength; j++) {
        const _seed =
          (filterLength &&
            highestDepthTaxonomies[j].split('.').slice(0, i).join('.')) ||
          null;
        const _depth = i ? 2 : initialTaxonomyDepth + 2;
        let params = { seed: _seed, depth: _depth };
        // Skip if already requested this time, otherwise, add to array
        if (seedsAlreadyRequested.includes(_seed)) {
          continue;
        } else {
          seedsAlreadyRequested.push(_seed);
        }
        // Add the request to the promises array
        promises.push(
          new Promise(resolve => {
            SupersetClient.get({
              endpoint: `${endpoint}?${qsStringify(params)}`,
            }).then(({ json }) => resolve(json?.response));
          }),
        );
      }
      // Increment level by 2 if anything has been selected, else, set
      // next level as two more than the initial level, since the dropdown
      // begins at taxonomy.iptc_media_topic.iptc_media_topic.xxxx
      i = i < initialTaxonomyDepth + 2 ? initialTaxonomyDepth + 2 : i + 2;
    }
    // Now fire off the requests
    Promise.all(promises)
      .then(promiseResults => {
        let newTaxonomies = promiseResults
          .flat()
          .filter(t => t.level % 2 === 1)
          .map(t => _taxonomyToAntdTreeNode(t));
        newTaxonomies = [...taxonomies, ...newTaxonomies].flat();
        // Get sorted list of unique taxonomies on id
        newTaxonomies = newTaxonomies
          .filter(
            (value, index, self) =>
              self.findIndex(t => t.id === value.id) === index,
          )
          .sort((a, b) => a.id > b.id);
        setTaxonomies(newTaxonomies);
        setError(undefined);
      })
      .catch(error => {
        console.log(error);
        setError(error);
      })
      .finally(() => setLoading(false));
  }, [taxonomiesFilter, loadTaxonomies]);
  return { loading, error, taxonomies };
};

export const TaxonomiesTreeSelect = ({
  taxonomiesFilter,
  onChange,
  style = {},
}) => {
  // Get taxonomies
  const [loadTaxonomies, setLoadTaxonomies] = useState([]);
  const {
    loading: taxonomiesLoading,
    error: taxonomiesError,
    taxonomies,
  } = useTaxonomies({ taxonomiesFilter, loadTaxonomies });
  // TODO: Take advantage of our grouped tree node structure
  return (
    <TreeSelect
      style={style}
      treeDataSimpleMode
      treeData={taxonomies}
      defaultValue={taxonomiesFilter}
      allowClear
      onChange={onChange}
      loadData={value =>
        new Promise(resolve => {
          setLoadTaxonomies([...loadTaxonomies, value.id]);
          resolve();
        })
      }
      treeCheckable
      showCheckedStrategy={TreeSelect.SHOW_PARENT}
      placeholder={'Filter taxonomies...'}
    />
  );
};
