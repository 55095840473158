import React, { useState, useEffect } from 'react';

import { SupersetClient, getNumberFormatter } from '@superset-ui/core';
import ContentLoader from 'react-content-loader';
import prettyBytes from 'pretty-bytes';
import { parseISO, formatDistance } from 'date-fns';
import { Card, Statistic, Alert } from 'antd';
import {
  BarsOutlined,
  ClockCircleOutlined,
  FolderOpenOutlined,
} from '@ant-design/icons';

import { qsStringify } from '../utils/fetch';

const CACHE = {};

const ContentLoaderLine = (
  <ContentLoader
    animate={true}
    speed={2}
    width={120}
    height={12}
    viewBox="0 0 120 12"
    backgroundColor="#f3f3f3"
    foregroundColor="#eaeaea"
  >
    <rect x="4" y="6" rx="5" ry="5" width="116" height="5" />
  </ContentLoader>
);

const useStatistics = ({ type, id, params, useCache = true }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(undefined);
  const [statistics, setStatistics] = useState(undefined);
  useEffect(() => {
    setError(undefined);
    setLoading(true);
    // Cache ensures that we don't fire off multiple statistics queries in
    // the same browser tab session.
    const cacheKey = type + id.toString() + JSON.stringify(params);
    let promise;
    if (useCache && CACHE?.[cacheKey]) {
      promise = CACHE[cacheKey];
    } else {
      const qs = qsStringify(params);
      promise = SupersetClient.get({
        endpoint: `/api/v1/${type}/${id}/statistics?${qs}`,
      });
      CACHE[cacheKey] = promise;
    }
    promise
      .then(({ json }) => {
        setStatistics(json);
      })
      .catch(err => {
        setError(err);
      })
      .finally(() => setLoading(false));
  }, [id, JSON.stringify(params)]);
  return { loading, error, statistics };
};

const useDatasetStatistics = ({ id, params, useCache = true }) =>
  useStatistics({ type: 'tentacle_dataset', id, params, useCache });

const useTableStatistics = ({ id, params, useCache = true }) =>
  useStatistics({ type: 'dataset', id, params, useCache });

const getUseStatistics = type =>
  type === 'dataset'
    ? useDatasetStatistics
    : type === 'table'
    ? useTableStatistics
    : undefined;

const StatisticsBase = ({
  type,
  id,
  params,
  onError = err => console.log(err),
  placeholder = ContentLoaderLine,
  errorText = 'Unavailable',
  statisticsToText,
}) => {
  const useStatistics = getUseStatistics(type);
  const { loading, error, statistics } = useStatistics({ id, params });
  if (error) {
    onError?.(error);
    return errorText;
  }
  if (loading || !statistics) {
    return placeholder;
  }
  const text = statisticsToText(statistics);
  return <span title={text}>{text}</span>;
};

export const formatRowCount = value =>
  ![null, undefined].includes(value) ? getNumberFormatter()(value) : undefined;

export const formatSize = value =>
  ![null, undefined].includes(value) ? prettyBytes(value) : undefined;

export const formatUpdate = (value, capitalize) => {
  const parsedISO = parseISO(value);
  const now = new Date();
  const text = ![null, undefined].includes(value)
    ? formatDistance(parsedISO, new Date(Math.max(parsedISO, now)), {
        includeSeconds: true,
        addSuffix: true,
      })
    : undefined;
  if (!text || !capitalize) return text;
  if (capitalize) return text[0].toUpperCase() + text.slice(1);
};

const Availability = ({
  type,
  prefix = null,
  unknown = 'Unavailable',
  params = undefined,
  ...props
}) => (
  <StatisticsBase
    type={type}
    params={{
      approximate_row_count: true,
      approximate_size: true,
      latest_update: false,
      ...(params || {}),
    }}
    statisticsToText={statistics => {
      // Construct pretty strings
      const rowCount = formatRowCount(statistics?.approximate_row_count);
      const size = formatSize(statistics?.approximate_size);
      // Construct various possibilities
      var text = unknown;
      if (rowCount && !size) text = rowCount;
      if (!rowCount && size) text = size;
      if (rowCount && size) text = `${rowCount} records (${size})`;
      return (prefix || '') + text;
    }}
    {...props}
  />
);

const LatestUpdate = ({
  type,
  prefix = null,
  unknown = 'Unavailable',
  params = undefined,
  ...props
}) => (
  <StatisticsBase
    type={type}
    params={{
      approximate_row_count: false,
      approximate_size: false,
      latest_update: true,
      ...(params || {}),
    }}
    statisticsToText={statistics => {
      let text = formatUpdate(statistics?.latest_update) || unknown;
      text = (prefix || '') + text;
      return text[0].toUpperCase() + text.slice(1);
    }}
    {...props}
  />
);

export const DatasetAvailability = props => (
  <Availability type="dataset" {...props} />
);

export const DatasetLatestUpdate = props => (
  <LatestUpdate type="dataset" {...props} />
);

export const TableAvailability = props => (
  <Availability type="table" {...props} />
);

export const TableLatestUpdate = props => (
  <LatestUpdate type="table" {...props} />
);

export const StatisticsCards = ({ type, id, params }) => {
  const { loading, error, statistics } = useStatistics({ type, id, params });
  if (error) {
    return (
      <Alert
        message={`Sorry! An error occured while fetching statistics.`}
        description={error?.message || error?.status || error?.toString()}
        type="warning"
      />
    );
  }
  return (
    <>
      {params?.approximate_row_count ? (
        <Card style={{ flexGrow: 1 }} bodyStyle={{ padding: '16px' }}>
          <Statistic
            valueStyle={{ fontSize: '20px' }}
            title="Records"
            loading={loading || !statistics}
            prefix={<BarsOutlined />}
            value={
              formatRowCount(statistics?.approximate_row_count) || 'Unavailable'
            }
          />
        </Card>
      ) : null}
      {params?.approximate_size ? (
        <Card style={{ flexGrow: 1 }} bodyStyle={{ padding: '16px' }}>
          <Statistic
            valueStyle={{ fontSize: '20px' }}
            title="Size"
            loading={loading || !statistics}
            prefix={<FolderOpenOutlined />}
            value={formatSize(statistics?.approximate_size) || 'Unavailable'}
          />
        </Card>
      ) : null}
      {params?.latest_update ? (
        <Card style={{ flexGrow: 1 }} bodyStyle={{ padding: '16px' }}>
          <Statistic
            valueStyle={{ fontSize: '20px' }}
            title="Latest Update"
            loading={loading || !statistics}
            prefix={<ClockCircleOutlined />}
            value={
              formatUpdate(statistics?.latest_update, true) || 'Unavailable'
            }
          />
        </Card>
      ) : null}
    </>
  );
};
