import React from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { Typography, Tag, Card, Pagination, Empty } from 'antd';

import {
  getAntdColorFromString,
  lineClampStyle,
  getNodeText,
} from '../utils/styles';
import Spin from './Spin';

export const TentacleCard = ({
  style = {},
  bodyStyle = {},
  href,
  avatar,
  title,
  extra,
  description,
  meta,
  tags,
  innerRef,
}) => (
  <div ref={innerRef} style={{ height: '100%' }}>
    <Card
      style={{ cursor: 'unset', height: '100%', ...style }}
      bodyStyle={{
        display: 'flex',
        flexDirection: 'row',
        padding: '16px',
        height: '100%',
        ...bodyStyle,
      }}
      hoverable={!!href}
    >
      {/* Avatar */}
      <div style={{ marginRight: !!avatar ? '8px' : undefined }}>
        {href ? (
          <Typography.Link href={href}>{avatar}</Typography.Link>
        ) : (
          <span>{avatar}</span>
        )}
      </div>
      {/* Content */}
      <div
        style={{
          minWidth: 0,
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {/* Title + Extra */}
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div style={{ ...lineClampStyle(1) }}>
            {href ? (
              <Typography.Link href={href}>
                <Typography.Title
                  level={5}
                  style={{ margin: 0 }}
                  title={getNodeText(title)}
                >
                  {title}
                </Typography.Title>
              </Typography.Link>
            ) : (
              <Typography.Title
                level={5}
                style={{ margin: 0 }}
                title={getNodeText(title)}
              >
                {title}
              </Typography.Title>
            )}
          </div>
          <span style={{ paddingLeft: '4px' }}>{extra}</span>
        </div>
        {/* Description */}
        <div
          title={description}
          style={{ fontSize: '0.9rem', ...lineClampStyle(2) }}
        >
          {description}
        </div>
        {/* Spacer to send Meta + Tags to bottom*/}
        <div style={{ flexGrow: 1, minHeight: '4px' }}></div>
        {/* Meta + Tags*/}
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
        >
          <div style={{ color: '#666', fontSize: '0.8rem' }}>
            {(meta || [])
              .map((m, i) => [<span key={i.toString()}>&nbsp;|&nbsp;</span>, m])
              .flat()
              .slice(1)}
          </div>
          <div>
            {tags?.map((t, i) => {
              const colorString = t?.color || t?.text || t || '';
              return (
                <Tag
                  key={t?.text || t}
                  style={{
                    color: getAntdColorFromString(colorString, 6),
                    backgroundColor: getAntdColorFromString(colorString, 0),
                    borderColor: getAntdColorFromString(colorString, 3),
                    fontSize: '0.8rem',
                    marginRight: i + 1 === tags.length ? 0 : undefined,
                  }}
                >
                  {t?.text || t}
                </Tag>
              );
            })}
          </div>
        </div>
      </div>
    </Card>
  </div>
);

export const TentacleCardGrid = ({
  dataSource,
  loading,
  pagination = {},
  itemToCard,
  columnWidth = 360,
  gap = 8,
}) => {
  const { width, ref } = useResizeDetector();
  const nCols = Math.floor((width + gap) / (columnWidth + gap)) || 1;
  const cardMaxWidth = (width - (nCols - 1) * gap) / nCols;
  const paginationPos = pagination?.position || ['topRight', 'bottomRight'];
  const paginator = pagination ? (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      {/* {loading ? <Spin style={{ width: 'unset' }} tip={undefined} /> : <div />} */}
      {<div />}
      <Pagination
        size="small"
        {...pagination}
        style={{
          margin: '16px 0',
          display: 'flex',
          justifyContent: 'flex-end',
          ...(pagination?.style || {}),
        }}
      />
    </div>
  ) : null;
  return (
    <>
      {paginationPos.includes('topRight') ? paginator : null}
      <div
        ref={ref}
        style={{
          position: 'relative',
          display: 'flex',
          alignItems: 'stretch',
          justifyContent:
            (dataSource?.length || 0) < nCols
              ? 'flex-start'
              : (dataSource?.length || 0) % nCols === 1
              ? 'flex-start'
              : 'center',
          flexWrap: 'wrap',
          gap: `${gap}px`,
          minHeight: '70px',
        }}
      >
        {/* Loading spinner overlay */}
        <div
          style={{
            position: 'absolute',
            width: '100%',
            height: loading ? '100%' : 0,
            overflow: 'hidden',
            backgroundColor: 'rgba(255, 255, 255, 0.6)',
            zIndex: 999,
            opacity: loading ? 1 : 0,
            transition: 'opacity 0.4s',
          }}
        >
          <Spin style={{ maxHeight: '280px' }} tip={null} />
        </div>
        {/* Empty indicator or grid items */}
        {dataSource?.length == 0 ? (
          <Empty
            style={{ margin: pagination ? 'auto' : '8px' }}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        ) : (
          (dataSource || []).map(c => (
            <div
              key={Math.random().toString()}
              style={{
                flexGrow: 1,
                minWidth: nCols === 1 ? undefined : `${columnWidth}px`,
                maxWidth: `${cardMaxWidth}px`,
              }}
            >
              {itemToCard?.(c)}
            </div>
          ))
        )}
      </div>
      {paginationPos.includes('bottomRight') ? paginator : null}
    </>
  );
};
